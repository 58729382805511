<template>
    <div>
        <div class="commen-hader">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12">
                        <h2>ABOUT</h2>
                    </div>
                </div>
            </div>
        </div>

        <div class="vacancies_area">
            <div class="container">
                <div class="row">COMING SOON</div>
            </div>
        </div>
    </div>
</template>

<script>
import { HTTP } from "../_helper/http-constants"
import axios from "axios"
import scrollTop from './utils/scrollTop'

export default {
    name: "About",

    data() {
        return {
            user: "",

            // imgsrc: 'http://103.212.120.205/~dev/public/admin_profile/',
            // imgsrc: "http://localhost/backend/public/admin_profile/",
            
            imgsrc:axios.defaults.uploadURL,
        }
    },

    mounted() {
        scrollTop()
        HTTP.get("/api/job").then((res) => {
            this.user = res.data
        })
    },
}
</script>
